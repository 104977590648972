<template>
    <div class="container">
        <div class="AboutUs">
            <p class="Us-h1">{{$t('title')}}</p>
            <div class="content-box">
                <h1 class="content-text">{{$t('content')}}</h1>
            </div>

            <div>
                <img class="Img" :src="Saidov" alt="Saidov Image">
            </div>

            <div class="Information">
                <p class="text-content">
                    {{$t('info')}}
                </p>
                <p class="text-content">
                    {{$t('specialists')}}
                </p>
                <p class="text-content">
                    {{$t('partnership')}}
                </p>
                <p class="text-content">
                    {{$t('conclusion')}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Saidov from '@/assets/doctor/saidov.webp'

export default{
    data(){
        return{
            Saidov
        }
    }
}
</script>




<style scoped>
.Us-h1 {
    text-align: center;
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 20px;
    font-weight: 500;
    margin: 30px 0;
}

.content-box {
    border: 2px solid #06829b;
    border-radius: 10px;
    padding: 20px;
    background-color: #ecf0f1;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

.content-box:hover {
    border-color: #1d78d1;
    background-color: #d6e9f5;
}

.content-text {
    font-size: 1.2rem;
    color: #34495e;
    line-height: 1.6;
    text-align: justify;
}

.Img {
    display: block;
    max-width: 100%;
    height: 100vh;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}


.Information {
    font-size: 1.1rem; 
    background: linear-gradient(135deg, #f9f9f9, #f2f2f2); 
    color: #2c3e50; 
    line-height: 1.75; 
    margin-top: 30px;
    margin: 30px auto; 
    padding: 30px;
    border: 2px solid #06829b; 
    border-radius: 12px; 
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out; 
}


.Information:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); 
    background: linear-gradient(135deg, #e6f7ff, #f2f2f2); 
}

.text-content {
    text-align: justify; 
    margin-bottom: 25px; 
    transition: max-height 0.5s ease-out, padding 0.3s ease; 
}

.text-content {
    transition: max-height 0.5s ease-out, padding 0.3s ease; 
    overflow: hidden; 
    max-height: 200px;
}

.show-more .text-content {
    max-height: 9999px; 
}

.ratings-container {
    border: 1px solid black;
    height: 20vh;
    border-radius: 10px;
    background: #ECF0F1;
    font-family: Arial, sans-serif;
    display: flex;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease-in-out; 
}

.ratings-container:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); 
}

.overall-rating {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 24px;
    font-weight: bold;
}

.rating-details {
    display: flex;
    gap: 350px;
    padding-left: 10px;
    font-size: 22px;
}

.rating {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.platform-icon {
    font-weight: bold;
    
}

.rating-value {
    color: #4caf50;
}

.review-count {
    color: #888;
  }

  @media (max-width: 600px) {
    .Us-h1 {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }

    .content-text {
        font-size: 0.6rem;
    }

    .Img {
        height: 40vh; 
    }

    .Information {
        padding: 15px;
        height: 100%;
    }

    .text-content {
        font-size: 0.6rem;
        height: 100%;
    }

    .content-box {
        padding: 15px;
    }
}

@media (min-width: 600px) and (max-width: 1024px) {
    .Us-h1 {
        font-size: 1.75rem;
        margin-bottom: 20px;
    }

    .content-text {
        font-size: 0.8rem;
    }

    .Img {
        height: 60vh;
    }

    .Information {
        padding: 20px;
    }

    .text-content {
        font-size: 0.8rem;
    }

    .content-box {
        padding: 20px;
    }
}


</style>
